/* Updated .banner */
.banner {
  height: 70vh; /* Reduced height, adjust as needed */
  width: 100%; /* Full width */
  position: relative;
  overflow: hidden;
  margin-top: 70px; /* Same as the navbar height */
}

/* Video styling for full coverage */
.banner video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video fills the container without distortion */
  z-index: 0; /* Behind all other elements */
}

/* Banner Overlay */
.banner-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure it overlays the video */
}

/* Banner Content */
.banner-content {
  z-index: 2; /* Place content above the overlay */
  color: #fff; /* White text */
  text-align: center; /* Center text horizontally */
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
  padding: 0px; /* Add padding for spacing */
 
}

.banner-content h1 {
  font-size: 4rem;
}
.banner-content p {
  font-size: 1rem;
}

.btn:hover {
  background-color: #1b6205; /* Darker shade on hover */
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Hovering button effect on the logo */
.logo-button {
  display: inline-block;
  text-decoration: none;
  transition: transform 0.3s ease-in-out;
}

.logo-button:hover {
  transform: scale(1.1); /* Zooms in the logo slightly */
  cursor: pointer; /* Adds a pointer cursor */
}

.logo-button img {
  transition: border-color 0.3s;
}

.logo-button:hover img {
  border-color: #ff9900; /* Changes border color on hover */
}

/* Style for paragraphs under sliders */
.slider-paragraph {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.8;
  text-align: justify;
  color: #333;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.slider-paragraph:first-letter {
  font-size: 1.5rem;
  font-weight: bold;
  color: #113805; /* Accent color for the first letter */
}

